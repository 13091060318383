import axios, { AxiosResponse } from "axios"
import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { TaskArea } from "../model/taskArea"
import { Task } from "../model/task"

export function TaskList({ active }: { active: boolean }) {
	const [tasks, setTasks] = useState<Task[]>([])
	const [area, setArea] = useState<TaskArea | undefined>()
	// const [currentMember, setCurrentMember] = useState<Member | null>(() => {
	// 	let localMember = localStorage.getItem("currentMember")
	// 	return !!localMember && localMember.trim() !== "undefined" ? JSON.parse(localMember!) : null
	// })

	let { areaParam } = useParams()

	const taskContainer = useRef<HTMLInputElement>(null)

	const getBGColor = (task: Task) => {
		return "dark:bg-zinc-800 bg-zinc-100 dark:text-zinc-100 text-zinc-800"
	}

	useEffect(() => {
		if (areaParam) {
			axios
				.post("https://fcchwork.randyslavey.com/api/getAreaList.php", {
					headers: {
						"Content-Type": "application/json",
					},
				})
				.then((response: AxiosResponse) => {
					return response.data
				})
				.then((responseData: TaskArea[]) => {
					let area: TaskArea =
						responseData.filter((taskArea: TaskArea) => {
							if (Number.isNaN(Number(areaParam))) {
								return taskArea.area_name.toLowerCase() === areaParam
							} else {
								return taskArea.area_id === Number(areaParam)
							}
						})[0] || null
					setArea(area)
				})
				.catch((error) => {
					alert(error)
				})
		}
	}, [areaParam])

	useEffect(() => {
		if (area) {
			axios
				.post(
					"https://fcchwork.randyslavey.com/api/getAreaTasks.php",
					{ area, active },
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				)
				.then((response: AxiosResponse) => {
					return response.data
				})
				.then((responseData: Task[]) => {
					let results = responseData.filter((task: Task) => {
						return !area || task.task_area_id === Number(area.area_id)
					})
					setTasks(results)
				})
				.catch((error) => {
					alert(error)
				})
		}
	}, [area, active])

	// useEffect(() => {
	// 	localStorage.setItem("currentMember", JSON.stringify(currentMember))
	// 	console.log(`currentMember: ${currentMember?.display_name}`)
	// }, [currentMember])

	return (
		<div ref={taskContainer} className="relative isolate text-2xl">
			<div className="mx-auto max-w-2xl pt-2 flex-col space-y-4 flex px-5 py-5 items-center">
				<h1 className="text-3xl">{`${area ? `${area.area_name} ` : ""}Tasks`}</h1>
				<button type="button" className="btn btn-primary" onClick={() => window.print()}>Add Task</button>
			</div>
			<div className="mx-auto max-w-2xl py-8 flex-col space-y-4">
				{tasks.map((task: Task, index: number) => {
					return (
						<details key={`task${task.task_id}`} className={`${getBGColor(task)} p-4 rounded-lg m-4`}>
							<summary className={`text-sm leading-6 font-semibold select-none`}>
								{task.task_name} ({task.frequency_name})
								{area ? "" : ` (${task.task_area_id})`}{" "}
							</summary>
							<div className="flex flex-col mt-3 text-sm leading-6 justify-items-end">
								<p>{task.task_notes}</p>
								{task.req_training ? (
									<p className="text-sm mt-1">{`NOTE: This task requires special training. Do not attempt without first consulting shop boss.`}</p>
								) : (
									""
								)}
							</div>
						</details>
					)
				})}
			</div>
		</div>
	)
}
