import "./App.css"
import { TodoList } from "./components/TodoList"
import { Route, Routes } from "react-router-dom"
import { Home } from "./pages/Home"
import { TaskList } from "./components/TaskList"
import AddTask from "./components/AddTask"

function App() {
	return (
		<Routes>
			<Route path="/" element={<Home />}></Route>
			<Route path="/todo/:areaParam" element={<TodoList active={true} />}></Route>
			<Route path="/task/:areaParam" element={<TaskList active={true} />}></Route>
			<Route path="/task/add/:areaParam" element={<AddTask />}></Route>
		</Routes>
	)

	// return (
	// 	<div className="App">
	// 		<section>
	// 			<TaskList active={true} area={params.area}></TaskList>
	// 		</section>
	// 	</div>
	// )
}

export default App
