import React, { useState } from "react"
import { Task } from "../model/task"
import axios from "axios"

const AddTask: React.FC = () => {
	const [task, setTask] = useState<Task>({} as Task)

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		task.task_name = event.target.value
		setTask(task)
	}

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		// Add your logic to handle the submission of the new task here
		console.log("New task:", task)
		axios.post("https://fcchwork.randyslavey.com/api/postTask.php", task).then((response) => {
			console.log(response)
		})
	}

	return (
		<form onSubmit={handleSubmit}>
			<label htmlFor="taskName">Task Name</label>
			<input title="taskName" type="text" value={task.task_name} onChange={handleInputChange} />
			<button type="submit">Add Task</button>
		</form>
	)
}

export default AddTask
