import axios, { AxiosResponse } from "axios"
import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { Member } from "../model/member"
import { TaskArea } from "../model/taskArea"
import { Todo } from "../model/todo"

export function TodoList({ active }: { active: boolean }) {
	const [todos, setTodos] = useState<Todo[]>([])
	const [showDialog, setShowDialog] = useState<boolean>(false)
	const [members, setMembers] = useState<Member[]>([])
	const [area, setArea] = useState<TaskArea | undefined>()
	const [currentMember, setCurrentMember] = useState<Member | null>(() => {
		let localMember = localStorage.getItem("currentMember")
		return !!localMember && localMember.trim() !== "undefined" ? JSON.parse(localMember!) : null
	})

	let { areaParam } = useParams()

	const taskContainer = useRef<HTMLInputElement>(null)

	const getAreaTodos = (area: TaskArea) => {
		axios
			.post("https://fcchwork.randyslavey.com/api/getAreaTodos.php", area, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((response: AxiosResponse) => {
				return response.data
			})
			.then((responseData: Todo[]) => {
				let results = responseData.filter((todo: Todo) => {
					return !area || todo.task_area_id === Number(area.area_id)
				})
				setTodos(results)
			})
			.catch((error) => {
				alert(error)
			})
		axios
			.post("https://fcchwork.randyslavey.com/api/getMemberList.php", {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((response: AxiosResponse) => {
				return response.data
			})
			.then((responseData: Member[]) => {
				setMembers(responseData)
			})
			.catch((error) => {
				alert(error)
			})
	}

	const markComplete = (todo: Todo) => {
		if (currentMember && currentMember.member_id) {
			axios
				.post(
					"https://fcchwork.randyslavey.com/api/completeTodo.php",
					{ todo_id: todo.id, completed_date: new Date(), member_id: currentMember.member_id },
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				)
				.then((response: AxiosResponse) => {
					return response.data
				})
				.then((responseData: Todo[]) => {
					getAreaTodos(area!)
				})
				.catch((error) => {
					alert(error)
				})
		}
	}

	const todoClicked = (todo: Todo) => {
		markComplete(todo)
		setShowDialog(!showDialog)
		console.log(`Selected todo: ${todo.task_name}`)
	}

	const getBGColor = (todo: Todo) => {
		return todo.task_req_training
			? "bg-red-800 text-zinc-100"
			: "dark:bg-zinc-800 bg-zinc-100 dark:text-zinc-100 text-zinc-800"
	}

	useEffect(() => {
		if (areaParam) {
			axios
				.post("https://fcchwork.randyslavey.com/api/getAreaList.php", {
					headers: {
						"Content-Type": "application/json",
					},
				})
				.then((response: AxiosResponse) => {
					return response.data
				})
				.then((responseData: TaskArea[]) => {
					let area: TaskArea =
						responseData.filter((taskArea: TaskArea) => {
							if (Number.isNaN(Number(areaParam))) {
								return taskArea.area_name.toLowerCase() === areaParam
							} else {
								return taskArea.area_id === Number(areaParam)
							}
						})[0] || null
					setArea(area)
				})
				.catch((error) => {
					alert(error)
				})
		}
	}, [areaParam])

	useEffect(() => {
		if (area) {
			getAreaTodos(area)
		}
	}, [area])

	useEffect(() => {
		localStorage.setItem("currentMember", JSON.stringify(currentMember))
		console.log(`currentMember: ${currentMember?.display_name}`)
	}, [currentMember])

	return (
		<div ref={taskContainer} className="relative isolate text-2xl">
			<div className="mx-auto max-w-2xl pt-2 flex-col space-y-4 flex px-5 py-5 items-center">
				<h1 className="text-3xl">{`${area ? `${area.area_name} ` : ""}Tasks`}</h1>
				<select
					id="member-select"
					name="member-select"
					title="Select Member"
					required
					value={currentMember ? currentMember.member_id : ""}
					onChange={(event) =>
						setCurrentMember(
							members.filter((member: Member) => {
								return member.member_id === Number(event.target.value)
							})[0]
						)
					}
				>
					<option key={0} value={`Select a Member`}>
						{`Select a Member`}
					</option>
					{members.map((member: Member, index: number) => (
						<option key={member.member_id} value={member.member_id}>
							{member.display_name}
						</option>
					))}
				</select>
			</div>
			<div className="mx-auto max-w-2xl py-8 flex-col space-y-4">
				{todos.map((todo: Todo, index: number) => {
					return (
						<details key={`todo${todo.id}`} className={`${getBGColor(todo)} p-4 rounded-lg m-4`}>
							<summary className={`text-sm leading-6 font-semibold select-none`}>
								{todo.task_name}
								{area ? "" : ` (${todo.task_area_id})`}{" "}
							</summary>
							<div className="flex flex-col mt-3 text-sm leading-6 justify-items-end">
								<p>{todo.task_notes}</p>
								{todo.task_req_training ? (
									<p className="text-sm mt-1">{`NOTE: This task requires special training. Do not attempt without first consulting shop boss.`}</p>
								) : (
									""
								)}
								<button
									type="button"
									onClick={() => todoClicked(todo)}
									disabled={currentMember === null || currentMember === undefined}
									className={`disabled:opacity-75 mt-4 bg-zinc-200 rounded-lg p-4 text-zinc-900 self-end`}
								>
									{currentMember === null || currentMember === undefined
										? "Select a member to complete"
										: "Mark Complete"}
								</button>
							</div>
						</details>
					)
				})}
			</div>
		</div>
	)
}
